import React from "react";
import PageTitle from "../PageTitle";
import { Breadcrumb, BreadcrumbItem } from "../Breadcrumb";
import PageContainer from "../PageContainer";
import {
    Container,
    CardWrapper,
    Card,
    CardHeader,
    CardContents,
    Price,
} from "./styles";

const TicketInfo = () => {
    return (
        <Container>
            <CardWrapper>
                <Card>
                    <CardHeader>Ticket Info</CardHeader>

                    <Price>
                        Full: $16 <br /> Concession: $12
                    </Price>
                </Card>
            </CardWrapper>
        </Container>
    );
};

export default TicketInfo;
