import { useStaticQuery, graphql } from "gatsby";
import { format, parse } from "date-fns";
import unique from "../../utils/unique";

const getData = () => {
    const result = useStaticQuery(graphql`
        query {
            allFilmsJson {
                edges {
                    node {
                        id
                        title
                        koreanTitle
                        director
                        posterFilePath
                        cast
                        note
                        oneLineDescription
                        schedule {
                            screeningDate
                            city
                            venue
                            bookingLink
                        }
                    }
                }
            }
            allFile(filter: { relativeDirectory: { eq: "films/posters" } }) {
                edges {
                    node {
                        name
                        childImageSharp {
                            fluid(maxWidth: 1200) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    `);
    return result;
};

export const getScreeningDates = (city: string) => {
    const data = getData();
    const schedule = data.allFilmsJson.edges
        .map(item => item.node.schedule)
        .flat()
        .filter(item => item.city === city);

    const venueDates = schedule
        .map(sched =>
            format(
                parse(sched.screeningDate, "yyyy-MM-dd HH:mm", new Date()),
                "yyyy-MM-dd E"
            )
        )
        .filter(unique)
        .sort()
        .map(date => {
            const formattedDate = date.split(" ");
            return {
                date: formattedDate[0],
                dow: formattedDate[1],
            };
        });
    const dateRange = `${format(
        new Date(venueDates[0].date),
        "dd"
    )} to ${format(
        new Date(venueDates[venueDates.length - 1].date),
        "dd LLL"
    ).toUpperCase()}`;

    const screeningDates = {
        city: schedule[0].city,
        cinema: schedule[0].venue,
        dates: venueDates,
        dateRange,
    };

    return screeningDates;
};

type Movie = {
    id: string;
    title: string;
    koreanTitle: string;
    poster: string;
    director: string;
    cast: string;
    genre: string;
    distributor: string;
    oneLineDescription: string;
};

const movies = (city: string, screeningDate?: string) => {
    const data = getData();

    const findPoster = (posters, path) => {
        if (!posters) return;

        return posters.find(poster => path.includes(poster.node.name))?.node
            .childImageSharp.fluid;
    };

    const result: [
        {
            id: string;
            to: string;
            title: string;
            koreanTitle: string;
            director: string;
            poster: string;
            path: string;
            cast: string;
            note: string;
            oneLineDescription: string;
            schedule: [
                {
                    city: string;
                    screeningDate: string;
                    venue: string;
                    bookingLink: string;
                }
            ];
        }
    ] = data.allFilmsJson.edges.map(item => ({
        id: item.node.id,
        to: `/films/${item.node.id}`,
        title: item.node.title,
        koreanTitle: item.node.koreanTitle,
        director: item.node.director,
        poster: findPoster(data.allFile.edges, item.node.posterFilePath),
        path: item.node.posterFilePath,
        screeningDate: item.node.screeningDate,
        cast: item.node.cast,
        oneLineDescription: item.node.oneLineDescription,
        schedule: item.node.schedule,
        note: item.node.note,
    }));

    var returnValue = [];
    result.forEach(element => {
        element.schedule.forEach((el, index) => {
            var r = {
                uniqueId: element.id + index,
                id: element.id,
                to: element.to,
                title: element.title,
                koreanTitle: element.koreanTitle,
                director: element.director,
                poster: element.poster,
                path: element.path,
                cast: element.cast,
                note: element.note,
                oneLineDescription: element.oneLineDescription,
                screeningDate: el.screeningDate,
                city: el.city,
                venue: el.venue,
                bookingLink: el.bookingLink,
            };
            returnValue.push(r);
        });
    });

    const r = returnValue

        .filter(item => item.city == city)
        .sort((a, b) => {
            const screeningDateA = parse(
                a.screeningDate,
                "yyyy-MM-dd HH:mm",
                new Date()
            );
            const screeningDateB = parse(
                b.screeningDate,
                "yyyy-MM-dd HH:mm",
                new Date()
            );
            if (screeningDateA < screeningDateB) return -1;
            if (screeningDateA > screeningDateB) return 1;
            return 0;
        });

    return screeningDate
        ? r.filter(item => item.screeningDate.indexOf(screeningDate) >= 0)
        : r;
};

export default movies;
