import React from "react";
import styled, { css } from "styled-components";

import { breakpoints, colors } from "../../styles/variables";
export const Container = styled.div`
    padding-top: 0;
    @media (min-width: ${breakpoints.sm}) {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
`;

export const CardWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: top;
    flex-direction: column;
    margin: 0.5rem 1.5rem 0;
    @media (min-width: ${breakpoints.sm}) {
        flex-direction: row;
    }
`;
export const Card = styled.div`
    border: 1px solid #efeaea;
    width: 50%;
    margin-bottom: 2rem;
    @media (min-width: ${breakpoints.sm}) {
        width: 50%;
        margin-bottom: 0;
        &:first-child {
            margin-right: 2rem;
        }
    }
`;

export const CardHeader = styled.p`
    font-size: 1.3rem;
    padding: 1rem 1rem 1rem 1rem;
    border-bottom: 1px solid #efeaea;
    background-color: #f7f5f530;

    @media (min-width: ${breakpoints.sm}) {
        padding: 1rem 1rem 1rem 2rem;
        font-size: 1.5rem;
    }
`;
export const CardContents = styled.p`
    padding-left: 1rem;

    &:last-child {
        padding-bottom: 2rem;
    }
    @media (min-width: ${breakpoints.sm}) {
        padding-left: 2rem;
    }
`;

export const Price = styled.p`
    color: ${colors.primary};
    padding: 1rem;
    font-size: 1.1rem;
    font-weight: 500;

    @media (min-width: ${breakpoints.sm}) {
        padding-left: 2rem;
        font-size: 1.2rem;
    }
`;
