import React from "react";
import { breakpoints, colors } from "../../styles/variables";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import styled, { css } from "styled-components";
import Img from "gatsby-image";
import { Link } from "gatsby";

export const Container = styled.div`
    padding-top: 0;
    @media (min-width: ${breakpoints.sm}) {
        padding-top: 1rem;
    }
`;

const Days = styled.div`
    scroll-snap-type: x mandatory;
    overflow-x: auto;
    display: flex;
    white-space: nowrap;
    border: solid 1px #eee;
`;

export const Date = styled.div`
    font-size: 1rem;
    letter-spacing: 1px;
    color: ${colors.darkGray};
    @media (min-width: ${breakpoints.sm}) {
        font-size: 1.5rem;
    }
`;

export const Dow = styled.div`
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: 1px;
    color: ${colors.darkGray};
    @media (min-width: ${breakpoints.sm}) {
        font-size: 1.3rem;
    }
`;

export const SliderWrapper = styled.div`
    position: relative;
    width: calc(100% - 2rem);
    margin: 0 1rem;

    & .slick-current {
        ${Date}, ${Dow} {
            color: ${colors.primary};
        }
    }

    @media (min-width: ${breakpoints.sm}) {
        width: calc(100% - 10rem);
        margin: 0 5rem;
    }
`;

export const Day = styled.div`
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-direction: column;

    &:last-of-type {
        border-right: 0;
    }
`;

export const ArrowStyle = css`
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    font-size: 1.5rem;
    font-weight: 700;
    color: ${colors.darkGray};
    cursor: pointer;
    transition: transform 0.4s;
    z-index: 1;
    &:hover {
        transform: translate(0, -50%) scale(1.2);
    }
    @media (min-width: ${breakpoints.sm}) {
        font-size: 3rem;
    }
`;

const ArrowLeft = styled(BsChevronLeft)`
    ${ArrowStyle}
    left: -1rem;
    @media (min-width: ${breakpoints.sm}) {
        left: -5rem;
    }
`;

const ArrowRight = styled(BsChevronRight)`
    ${ArrowStyle}
    right: -1rem;
    @media (min-width: ${breakpoints.sm}) {
        right: -5rem;
    }
`;
export const Left = ({ onClick }: { onClick?: () => void }) => (
    <ArrowLeft onClick={onClick} />
);

export const Right = ({ onClick }: { onClick?: () => void }) => (
    <ArrowRight onClick={onClick} />
);

export const Movies = styled.div`
    margin: 0;
    padding: 0;
    @media (min-width: ${breakpoints.sm}) {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin: 2rem 0 2rem 0;
        padding: 0 1rem;
    }
`;

export const InfoContainer = styled.div`
    margin: 1rem 0 0 0;
    display: flex;
    flex-direction: column;
    padding: 0 0 2rem 0;
    border-bottom: solid 1px #d8d8d8;
    @media (min-width: ${breakpoints.sm}) {
        margin: 0;
        flex-direction: row;
        border-top: solid 1px #d8d8d8;
        border-bottom: none;
        padding: 3rem 0 3rem 0;
    }
`;

export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
export const InfoBox = styled.div`
    display: flex;
    flex-direction: row;
`;
export const InfoTitle = styled.p`
    padding-right: 2rem;
    width: 7rem;
    min-width: 7rem;
    color: ${colors.lightDark};
    text-decoration: none;
    font-weight: 500;
`;

export const InfoContents = styled.p`
    color: ${colors.dark};
    text-decoration: none;
`;
export const InfoMovieTitle = styled.p`
    color: ${colors.dark};
    text-decoration: none;
    margin: 0;
`;
export const InfoNote = styled.p`
    color: ${colors.dark};
    text-decoration: none;
    margin: auto 0 0;
    padding: 0 0 0.5rem 2rem;
`;

export const StyledLink = styled(Link)`
    display: inline-block;
    margin: 1rem 0 0 0;
    max-width: 10rem;
    text-align: center;
    color: #fff;
    border: solid 1px ${colors.dark};
    background-color: ${colors.dark};
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 0.5rem 1.5rem;
    font-size: 0.8rem;
    border-radius: 2px;
    &:hover {
        color: #fff;
        background-color: ${colors.darkGray};
        border-color: ${colors.darkGray};
    }
`;

export const Poster = styled(Img)`
    width: 100%;
    height: auto;
    margin: 2rem 2rem 2rem 0;

    @media (min-width: ${breakpoints.sm}) {
        width: 11rem;
        margin: auto 2rem auto 0;
        min-width: 11rem;
    }
`;

export const DateRange = styled.h2`
    text-align: center;
    padding-right: 0.5rem;
    color: ${colors.lightDark};
    @media (min-width: ${breakpoints.sm}) {
        flex-direction: row;
    }
`;
export const Cinema = styled.h4`
    text-align: center;
    margin-bottom: 1rem;
    font-size: 1rem;
    color: ${colors.lightDark};
    @media (min-width: ${breakpoints.sm}) {
        font-size: 1.5rem;
    }
`;
export const DateWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;
export const BtnWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    @media (min-width: ${breakpoints.sm}) {
        justify-content: flex-start;
    }
`;
export const BookButton = styled.a`
    display: inline-block;
    margin: 1rem 0 0 0;
    color: #fff;
    border: solid 1px ${colors.primary};
    background-color: ${colors.primary};
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 0.5rem 1.5rem;
    border-radius: 2px;
    font-size: 0.8rem;
    max-width: 10rem;
    text-align: center;
    cursor: pointer;

    &:hover {
        color: #fff;
        background-color: ${colors.lightBlue};
        border-color: ${colors.lightBlue};
    }
    @media (min-width: ${breakpoints.sm}) {
        margin: 1rem 0 0 2rem;
    }
`;
export const InlineLink = styled(Link)`
    color: ${colors.dark};
    &:hover {
        color: ${colors.primary};
    }
`;
