import React, { useEffect, useState, FunctionComponent } from "react";
import { format, isAfter, parse } from "date-fns";
import PageTitle from "../PageTitle";
import { Breadcrumb, BreadcrumbItem } from "../Breadcrumb";
import PageContainer from "../PageContainer";
import Slider from "react-slick";
import {
    Left,
    Right,
    Container,
    SliderWrapper,
    Day,
    Date as StyledDate,
    Dow,
    Movies,
    InfoBox,
    InfoContainer,
    InfoContents,
    InfoMovieTitle,
    InfoTitle,
    InfoNote,
    InfoWrapper,
    Poster,
    StyledLink,
    DateRange,
    Cinema,
    DateWrapper,
    BtnWrapper,
    BookButton,
    InlineLink,
} from "./styles";
import movies, { getScreeningDates } from "./useData";
import TicketInfo from "../ticket-info";

type Props = {
    city?: string;
};

const Schedule: FunctionComponent<Props> = ({ city }) => {
    const screeningDates = getScreeningDates(city);
    const screeningDate: { date: string; dow: string }[] = screeningDates.dates;
    const dateRange = screeningDates.dateRange;
    const cinema = screeningDates.cinema;
    const [activeSlide, setActiveSlide] = useState(0);
    const [activeDate, setActiveDate] = useState(screeningDate[0].date);
    const slideSettings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        infinite: true,
        speed: 300,
        cssEase: "linear",
        autoplay: false,
        prevArrow: <Left />,
        nextArrow: <Right />,
        focusOnSelect: true,
        afterChange: current => setActiveSlide(current),
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 3,
                },
            },
        ],
    };

    const moviesList = movies(city, activeDate);

    useEffect(() => {
        setActiveDate(screeningDate[activeSlide].date);
    }, [activeSlide]);

    return (
        <PageContainer>
            <Breadcrumb>
                <BreadcrumbItem Link={"/schedule/"} Text={"Schedule"} />
            </Breadcrumb>
            <PageTitle>{city}</PageTitle>
            <Container>
                <DateWrapper>
                    <DateRange>{dateRange}</DateRange>
                    <Cinema> {cinema}</Cinema>
                </DateWrapper>
                <SliderWrapper>
                    <Slider {...slideSettings}>
                        {screeningDate.map(({ date, dow }) => (
                            <div key={date}>
                                <Day>
                                    <StyledDate>
                                        {format(
                                            parse(
                                                date,
                                                "yyyy-MM-dd",
                                                new Date()
                                            ),
                                            "dd/MM"
                                        )}
                                    </StyledDate>
                                    <Dow>{dow}</Dow>
                                </Day>
                            </div>
                        ))}
                    </Slider>
                </SliderWrapper>
                <Movies>
                    {moviesList.length > 0 ? (
                        moviesList.map(movie => {
                            return (
                                <InfoContainer key={movie.uniqueId}>
                                    <Poster fluid={movie.poster} />
                                    <InfoWrapper>
                                        <InfoBox>
                                            <InfoTitle>Showtime</InfoTitle>
                                            <InfoContents>
                                                {format(
                                                    parse(
                                                        movie.screeningDate,
                                                        "yyyy-MM-dd HH:mm",
                                                        new Date()
                                                    ),
                                                    "HH:mm EEEE d MMMM"
                                                )}
                                            </InfoContents>
                                        </InfoBox>
                                        <InfoBox>
                                            <InfoTitle>Title</InfoTitle>
                                            <div>
                                                <InfoMovieTitle>
                                                    {movie.title}{" "}
                                                    {city === "Sydney" &&
                                                    [
                                                        "DOG DAYS",
                                                        "FAQ",
                                                        "PICNIC",
                                                    ].includes(movie.title) ? (
                                                        <>
                                                            +{" "}
                                                            <InlineLink to="/festival-guests">
                                                                Q&A with
                                                                Director
                                                            </InlineLink>
                                                        </>
                                                    ) : undefined}
                                                </InfoMovieTitle>
                                                <InfoContents>
                                                    {movie.koreanTitle}{" "}
                                                    {city === "Sydney" &&
                                                    movie.koreanTitle ===
                                                        "올빼미" ? (
                                                        <>
                                                            +{" "}
                                                            <InlineLink to="/festival-guests">
                                                                게스트 Q&A
                                                            </InlineLink>
                                                        </>
                                                    ) : undefined}
                                                </InfoContents>
                                            </div>
                                        </InfoBox>
                                        <InfoBox>
                                            <InfoTitle>Director</InfoTitle>
                                            <InfoContents>
                                                {movie.director}
                                            </InfoContents>
                                        </InfoBox>
                                        <InfoBox>
                                            <InfoTitle>Cast</InfoTitle>
                                            <InfoContents>
                                                {movie.cast}
                                            </InfoContents>
                                        </InfoBox>
                                        <BtnWrapper>
                                            <StyledLink to={movie.to}>
                                                More info
                                            </StyledLink>

                                            {movie.bookingLink &&
                                                movie.bookingLink !== "" &&
                                                isAfter(
                                                    parse(
                                                        movie.screeningDate,
                                                        "yyyy-MM-dd HH:mm",
                                                        new Date()
                                                    ),
                                                    new Date()
                                                ) &&
                                                (movie.bookingLink ===
                                                "Sold Out" ? (
                                                    <InfoNote>
                                                        {movie.bookingLink}
                                                    </InfoNote>
                                                ) : (
                                                    <BookButton
                                                        href={movie.bookingLink}
                                                        target="_blank"
                                                    >
                                                        Book now
                                                    </BookButton>
                                                ))}
                                            {(!movie.bookingLink ||
                                                movie.bookingLink === "") && (
                                                <InfoNote>
                                                    Booking info available soon
                                                </InfoNote>
                                            )}
                                        </BtnWrapper>
                                    </InfoWrapper>
                                </InfoContainer>
                            );
                        })
                    ) : (
                        <DateWrapper>
                            <Cinema>No Screening</Cinema>
                        </DateWrapper>
                    )}
                </Movies>
                <TicketInfo />
            </Container>
        </PageContainer>
    );
};

export default Schedule;
